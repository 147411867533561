import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { data, numberOfPosts } = this.props
    const { edges: posts } = data.allMarkdownRemark

    const filteredPosts = posts.slice(0, numberOfPosts)
    // console.log('filteredPosts: ', filteredPosts)
    return (
      <div className="columns is-multiline">
        {filteredPosts &&
          filteredPosts.map(({ node: post }) => {
            if (!post.frontmatter.featuredpost) {
              return null
            }
            return (
              <div className="is-parent column is-6" key={post.id}>
                <article
                  className={`blog-list-item tile is-child notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  <header>
                    {post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </div>
                    ) : null}
                    <p className="post-meta">
                      <Link
                        className="title has-text-primary is-size-4"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>
                      {/* <span> &bull; </span> */}
                      {/* <span className="subtitle is-size-5 is-block">
                      {post.frontmatter.date}
                    </span> */}
                    </p>
                  </header>
                  <p>
                    {/* {post.excerpt} */}
                    {/* <br />
                  <br /> */}
                    <Link className="button" to={post.fields.slug}>
                      Keep Reading →
                    </Link>
                  </p>
                </article>
              </div>
            )
          })}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQueryFeaturedPosts {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "catalogue-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                # featuredimage {
                #   childImageSharp {
                #     fluid(maxWidth: 120, quality: 100) {
                #       ...GatsbyImageSharpFluid
                #     }
                #   }
                # }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => {
      return <BlogRoll data={data} count={count} />
    }}
  />
)
